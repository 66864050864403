:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --accent-color: #e74c3c;
  --background-color: #ecf0f1;
  --text-color: #34495e;
  --header-bg-color: #f8f9fa;
  --footer-bg-color: #2c3e50;
}

body {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  position: sticky;
  top: 0;
  background-color: var(--header-bg-color);
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1001;
  height: 100px;
  box-sizing: border-box;
}

.header-content {
  display: flex;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  position: relative; /* 追加 */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1002; /* ヘッダーより上 */
  display: none;
}

.overlay.active {
  display: block;
}


.hamburger-button { /* .menu-buttonから変更 */
  background: none;
  border: none;
  cursor: pointer;
  color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  z-index: 1004;
  position: absolute;
  left: 0;
}

.side-menu {
  position: fixed;
  top: 0;
  left: -300px; /* 初期状態では画面外 */
  width: 300px;
  height: 100vh;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  z-index: 1003; /* オーバーレイより上、ボタンより下 */
  overflow-y: auto;
}

.side-menu.active {
  transform: translateX(300px);
}

.hamburger-icon { /* .menu-iconから変更 */
  display: block;
  width: 24px;
  height: 2px;
  background-color: var(--secondary-color);
  position: relative;
}

.hamburger-icon::before,
.hamburger-icon::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--secondary-color);
  left: 0;
}

.hamburger-icon::before {
  top: -6px;
}

.hamburger-icon::after {
  bottom: -6px;
}

.header-titles {
  flex-grow: 1; /* 追加 */
  display: flex;
  flex-direction: column;
  align-items: center; /* 変更 */
  margin: 0 auto; /* 追加 */
}


.site-title {
  font-size: 1.8rem;
  margin: 0;
  color: var(--primary-color);
  font-weight: 700;
}

.service-title {
  font-size: 1rem;
  margin: 0.2rem 0 0;
  font-weight: 400;
  color: var(--secondary-color);
}

.beta-tag {
  font-size: 0.6rem;
  vertical-align: super;
  color: var(--accent-color);
  margin-left: 0.3rem;
  font-weight: 700;
}

.main-content {
  flex-grow: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  overflow: visible; /* これを追加 */
}

.footer {
  background-color: var(--footer-bg-color);
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.footer a {
  margin: 0 0.5rem;
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.search-form {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.search-results {
  margin-top: 20px;
  width: 100%;
}
.input-wrapper {
  position: sticky;
  top: 80px; /* ヘッダーの高さ分下げる */
  background-color: white;
  z-index: 1000;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
}

.search-form input[type="text"] {
  width: 100%;
  padding: 12px 45px 12px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  outline: none;
}
.search-form form {
  position: sticky;
  top: calc(100px + 1px); /* ヘッダーの高さ + 少しの余白 */
  z-index: 1000;
  padding: 10px 20px;
  margin: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.search-form form button {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}

.suggestions li:last-child {
  border-radius: 0 0 15px 15px;
}

.sample-questions {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
  margin-top: 20px; /* 60pxから20pxに減らす */
}

.sample-questions button {
  padding: 10px 15px;
  background-color: white;
  color: #333;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  font-size: 14px;
}

.sample-questions button:hover {
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  transform: translateY(-2px);
}

.sample-questions button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.sample-questions button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

.result {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  animation: fadeIn 0.3s ease-in-out;
}

.result h1, .result h2, .result h3, .result h4 {
  color: var(--secondary-color);
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.result h1 { font-size: 1.8em; }
.result h2 { font-size: 1.5em; }
.result h3 { font-size: 1.3em; }
.result h4 { font-size: 1.1em; }

.result p {
  margin-bottom: 0.5em;
  line-height: 1.6;
}

.result ul, .result ol {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-left: 1.5em;
  list-style-type: none;
}

.result li {
  margin-bottom: 0.3em;
  position: relative;
}

.result ul > li::before {
  content: "›";
  color: var(--primary-color);
  position: absolute;
  left: -1em;
  font-size: 1.2em;
}

.result ul > li > ul > li::before {
  content: "‣";
  color: var(--secondary-color);
  font-size: 1.1em;
}

.result ul > li > ul > li > ul > li::before {
  content: "⁃";
  color: var(--accent-color);
}

.result ul > li > ul > li > ul > li > ul li::before {
  content: "∙";
  color: var(--text-color);
  font-size: 1.2em;
}

.result ol {
  counter-reset: item;
}

.result ol > li {
  counter-increment: item;
}

.result ol > li::before {
  content: counter(item) ".";
  color: var(--primary-color);
  font-weight: bold;
  position: absolute;
  left: -1.5em;
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-indicator p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 1em;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9em;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
}

th {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

tr:nth-child(even) {
  background-color: #f8f9fa;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 768px) {
  .menu-button {
    margin-bottom: 0.5rem;
  }

  .header-titles {
    align-items: center;
    width: 100%;
  }

  .site-title {
    font-size: 1.5rem;
  }

  .service-title {
    font-size: 0.9rem;
  }

  .main-content {
    padding: 1rem;
  }
  /* search-formの調整 */
  .search-form {
    gap: 10px;
  }
  .search-form form {
    top: calc(100px + 1px); /* モバイルヘッダーの高さ + 少しの余白 */
    padding: 10px;
  }
  /* search-resultsの調整 */
  .search-results {
    padding: 0 10px;
  }

  .input-wrapper {
    top: 70px; /* モバイルでのヘッダーの高さに合わせる */
    padding: 10px;
  }

  .search-form input[type="text"] {
    padding: 10px 40px 10px 10px;
    font-size: 14px;
  }

  .search-form form button {
    width: 32px;
    height: 32px;
    right: 15px;
  }

  .sample-questions {
    margin-top: 15px; /* モバイルでのフォームの高さに合わせて調整 */
  }

  .sample-questions button {
    width: 100%;
  }

  table {
    font-size: 0.8em;
  }
  
  th, td {
    padding: 8px;
  }
}