/* サイドメニューの基本スタイル */
.side-menu {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  z-index: 1003;
  overflow-y: auto;
}

.side-menu.active {
  transform: translateX(300px);
}

/* メニューコンテンツ */
.menu-content {
  padding: 20px;
  margin-top: 40px; /* 閉じるボタンの下に配置 */
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content li {
  margin: 15px 0;
}

.menu-content a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 10px;
  transition: all 0.3s ease;
}

.menu-content a:hover {
  color: var(--primary-color);
  background-color: #f5f5f5;
  border-radius: 5px;
}

.menu-button {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 16px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.menu-button:hover {
  color: var(--primary-color);
  background-color: #f5f5f5;
  border-radius: 5px;
}

/* 閉じるボタン */
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--text-color);
  padding: 5px 10px;
  transition: all 0.3s ease;
  z-index: 1;
}

.close-button:hover {
  color: var(--primary-color);
}

/* モバイル対応 */
@media (max-width: 768px) {
  .side-menu {
    width: 250px;
    left: -250px;
  }

  .side-menu.active {
    transform: translateX(250px);
  }

  .menu-content {
    padding: 15px;
  }

  .menu-content a {
    font-size: 14px;
    padding: 8px;
  }
}